import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import organizationSlice from './controller/organizationSlice'
import groupSlice from './controller/groupSlice'
import locationSlice from './controller/locationSlice'
import vehicleTypeSlice from './controller/vehicleTypeSlice'
import drivingLicenseSlice from './controller/drivingLicenseSlice'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
}
function sidebarReducer(state = initialState, { type, ...rest }) {
  if (type === 'set') {
    return {
      ...state,
      ...rest,
    }
  }
  return state
}

const store = {
  reducer: {
    sidebar: sidebarReducer,
    organization: organizationSlice,
    group: groupSlice,
    location: locationSlice,
    vehicleType: vehicleTypeSlice,
    drivingLicense: drivingLicenseSlice,
  },
}

if (process.env.NODE_ENV === 'development') {
  store.middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
}

export default configureStore(store)
