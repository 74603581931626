import { USE_AUTH, STORAGE_TYPE, APPS_AUTH_USER } from './config'

function _getAuthUserAccessToken() {
  let user = null
  if (STORAGE_TYPE === 'session') {
    user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
  } else {
    user = JSON.parse(localStorage.getItem(APPS_AUTH_USER))
  }

  if (user && user.token) {
    return user.token
  } else {
    return null
  }
}

export function checkAutorization() {
  if (USE_AUTH) {
    if (_getAuthUserAccessToken()) {
      return true
    }
    return false
  } else {
    console.log('Auth System Is Disabled. Please Enable it at auth.js')
    return true
  }
}
export function getAuthEnabled() {
  return USE_AUTH
}
export function getAuthUser() {
  let user = null
  if (STORAGE_TYPE === 'session') {
    user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
  } else {
    user = JSON.parse(localStorage.getItem(APPS_AUTH_USER))
  }

  if (user) {
    return user
  } else {
    return null
  }
}
export function getAuthUserAccessToken() {
  return _getAuthUserAccessToken()
}
export function getAuthUserId() {
  let user = null
  if (STORAGE_TYPE === 'session') {
    user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
  } else {
    user = JSON.parse(localStorage.getItem(APPS_AUTH_USER))
  }

  if (user) {
    return user.uid
  } else {
    return null
  }
}
export function getAuthUserRole() {
  let user = null
  if (STORAGE_TYPE === 'session') {
    user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
  } else {
    user = JSON.parse(localStorage.getItem(APPS_AUTH_USER))
  }

  if (user) {
    return user.role
  } else {
    return null
  }
}
export function getAuthUserName() {
  let user = null
  if (STORAGE_TYPE === 'session') {
    user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
  } else {
    user = JSON.parse(localStorage.getItem(APPS_AUTH_USER))
  }

  if (user) {
    return user.name
  } else {
    return null
  }
}
