import { createSlice } from '@reduxjs/toolkit'
import NFetch from 'src/libcore/nfetch'
import { API_ROUTE } from 'src/libcore/config'
import store from 'src/store'

export const getDrivingLicense = async (reload = false) => {
  if (reload) {
    return NFetch.Get(API_ROUTE + '/compound-driving-licenses')
  }
  const { drivingLicense } = store.getState()
  if (drivingLicense.data.length === 0) {
    return NFetch.Get(API_ROUTE + '/compound-driving-licenses')
  } else {
    return Promise.resolve(drivingLicense.data)
  }
}
export const drivingLicenseSlice = createSlice({
  name: 'drivingLicense',
  initialState: {
    data: [],
  },
  reducers: {
    drivingLicenseData(state, action) {
      state.data = action.payload
    },
  },
})

export const { drivingLicenseData } = drivingLicenseSlice.actions
export default drivingLicenseSlice.reducer
