import NFetch from '../nfetch'
import { API_ROUTE, STORAGE_TYPE, APPS_AUTH_USER } from '../config'
import Dates from '../dates'

const LOGIN_PATH = '/auth/user/login'
const LOGOUT_PATH = '/auth/user/logout'

class Authentication {
  static async loginByUsername(username, password) {
    return this.login({ username: username, password: password })
  }
  static async loginByEmail(email, password) {
    return this.login({ email: email, password: password })
  }
  static async loginByUsernameOrEmail(usernameOrEmail, password) {
    return this.login({ usernameOrEmail: usernameOrEmail, password: password })
  }
  static async login(credentials) {
    try {
      let result = await NFetch.Post(API_ROUTE + LOGIN_PATH, credentials)
      // console.log(result)
      let AuthData = {
        token: result.accessToken ? result.accessToken : '',
        refreshToken: result.refreshToken ? result.accessToken : '',
        name: result.name ? result.name : result.fullname || '',
        username: result.username ? result.username : '',
        uid: result.uid ? result.uid : '',
        role: result.role ? result.role : '',
        contact: result.contact ? result.contact : '',
        company: result.company ? result.company : '',
        created: result.created
          ? Dates.getDateTimeWithFormat(result.created, Dates.FORMAT.DATE_TIME5)
          : '',
        picture: result.picture ? result.picture : '',
      }
      this.saveAuthUser(AuthData)
      return Promise.resolve(AuthData)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  static async logout() {
    try {
      await NFetch.Post(API_ROUTE + LOGOUT_PATH)
      return Promise.resolve('OK')
    } catch (error) {
      return Promise.reject(error)
    }
  }
  static getAuthUser() {
    let user =
      STORAGE_TYPE === 'session'
        ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
        : JSON.parse(localStorage.getItem(APPS_AUTH_USER))

    if (user) {
      return user
    } else {
      return null
    }
  }
  static getAuthUserAccessToken() {
    let user =
      STORAGE_TYPE === 'session'
        ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
        : JSON.parse(localStorage.getItem(APPS_AUTH_USER))
    if (user && user.token) {
      return user.token
    } else {
      return null
    }
  }
  static saveAuthUser(user) {
    if (STORAGE_TYPE === 'session') {
      sessionStorage.setItem(APPS_AUTH_USER, JSON.stringify(user))
    } else {
      localStorage.setItem(APPS_AUTH_USER, JSON.stringify(user))
    }
  }
  static removeAuthUser() {
    if (STORAGE_TYPE === 'session') {
      sessionStorage.removeItem(APPS_AUTH_USER)
    } else {
      localStorage.removeItem(APPS_AUTH_USER)
    }
  }
}
export default Authentication
