import { createSlice } from '@reduxjs/toolkit'
import NFetch from 'src/libcore/nfetch'
import { API_ROUTE } from 'src/libcore/config'
import store from 'src/store'

export const getAccessGroup = async (reload = false) => {
  if (reload) {
    return NFetch.Get(API_ROUTE + '/access-groups')
  }
  const { group } = store.getState()
  if (group.accessGroup.length === 0) {
    return NFetch.Get(API_ROUTE + '/access-groups')
  } else {
    return Promise.resolve(group.accessGroup)
  }
}
export const getUserGroup = async (reload = false) => {
  if (reload) {
    return NFetch.Get(API_ROUTE + '/user-groups')
  }
  const { group } = store.getState()
  if (group.userGroup.length === 0) {
    return NFetch.Get(API_ROUTE + '/user-groups')
  } else {
    return Promise.resolve(group.userGroup)
  }
}
export const groupSlice = createSlice({
  name: 'group',
  initialState: {
    userGroup: [],
    accessGroup: [],
  },
  reducers: {
    groupUserGroup(state, action) {
      state.userGroup = action.payload
    },
    groupAccessGroup(state, action) {
      state.accessGroup = action.payload
    },
  },
})

export const { groupUserGroup, groupAccessGroup } = groupSlice.actions
export default groupSlice.reducer
