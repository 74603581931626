import dateFormat from 'dateformat'

class Dates {
  static FORMAT = {
    DATE1: 'yyyy-mm-dd', // 2019-05-05
    DATE2: 'yyyy/mm/dd', // 2019/05/05
    DATE3: 'dd/mm/yyyy', // 05/12/2020
    DATE4: 'dd-mm-yyyy', // 05-12-2020
    TIME1: 'HH:MM', // 16:05
    TIME2: 'HH:MM:ss', // 16:05:05
    TIME3: 'hh:MM TT', // 1:05 AM
    TIME4: 'hh:MM:ss TT', // 6:05 PM
    DATE_TIME1: 'yyyy-mm-dd HH:MM', // 2019-05-05 16:05
    DATE_TIME2: 'dd-mm-yyyy HH:MM', // 05-12-2020 16:05
    DATE_TIME3: 'dd/mm/yyyy HH:MM', // 05/12/2020 16:05
    DATE_TIME4: 'dd-mm-yyyy HH:MM:ss', // 05/12/2020 16:05:35
    DATE_TIME5: 'dd/mm/yyyy hh:MM TT', // 05/12/2020 04:05 PM
  }
  static getDiffTime(date_from, date_to) {
    let _diff = Math.abs(new Date(date_from) - new Date(date_to)) / 1000
    var year = Math.floor(_diff / 946080000)
    var month = Math.floor(_diff / 2592000) % 30
    var days = Math.floor(_diff / 86400)
    var hours = Math.floor(_diff / 3600) % 24
    var minutes = Math.floor(_diff / 60) % 60
    var seconds = Math.floor(_diff % 60)

    return { year: year, month: month, day: days, hour: hours, minute: minutes, second: seconds }
  }
  static getDiffTimeByDay(date_from, date_to) {
    //console.log(date_from)
    let _diff = Math.abs(new Date(date_from) - new Date(date_to)) / 1000
    return Math.floor(_diff / 86400)
  }
  static getDateTimeWithFormat(datetime, format) {
    // console.log(datetime)
    if (typeof datetime === 'undefined') {
      return ''
    }
    if (datetime === null) {
      return ''
    }

    let dates = dateFormat(datetime, format)
    return dates
  }
  static format(datetime, format) {
    if (typeof datetime === 'undefined') {
      return ''
    }
    if (datetime === null) {
      return ''
    }
    let dates = dateFormat(datetime, format)
    return dates
  }
  static getToday(format) {
    return dateFormat(format)
  }
  static getDatesBetween(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt))
    }
    return arr
  }
  static getSubtractDate(daySubtract, currenDate) {
    if (typeof currenDate === 'undefined') {
      return new Date(Date.now() - daySubtract * 24 * 60 * 60 * 1000)
    }

    return new Date(new Date(currenDate).getTime() - daySubtract * 24 * 60 * 60 * 1000)
  }
  static getAddDate(dayAdded, currenDate) {
    if (typeof currenDate === 'undefined') {
      return new Date(Date.now() + dayAdded * 24 * 60 * 60 * 1000)
    }

    return new Date(new Date(currenDate).getTime() + dayAdded * 24 * 60 * 60 * 1000)
  }
  static getNumberPrevMonth(year, month) {
    // month = 1-12
    if (month === 1) {
      return {
        year: year - 1,
        month: 12,
      }
    }

    return {
      year: year,
      month: month - 1,
    }
  }
  static getNumberNextMonth(year, month) {
    // month = 1-12
    if (month === 12) {
      return {
        year: year + 1,
        month: 1,
      }
    }

    return {
      year: year,
      month: month + 1,
    }
  }
  static getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
  }
  static getDateFromDates(dates) {
    dates = new Date(dates)

    return {
      date: dates.getDate(),
      month: dates.getMonth() + 1,
      year: dates.getFullYear(),
    }
  }
}
export default Dates
