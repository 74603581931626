var HOST_SERVER = window.location.hostname,
  API_ROUTE = '',
  MQTT_URL = ''
let dev = true
if (typeof process.env.REACT_APP_MODE === 'undefined') {
  if (typeof process.env.NODE_ENV !== 'undefined') {
    if (process.env.NODE_ENV === 'production') {
      dev = false
    }
  }
} else {
  if (process.env.REACT_APP_MODE !== 'dev') {
    dev = false
  }
}

// Test
// dev = false
// HOST_SERVER = 'pss.dev.innates.my'
// HOST_SERVER = 'pss.tlpterminal.innates.my'

if (dev) {
  API_ROUTE = 'http://' + HOST_SERVER + ':3070/api'
  MQTT_URL = 'ws://' + HOST_SERVER + ':4891'
} else {
  HOST_SERVER = 'pss.tlpterminal.innates.my'
  API_ROUTE = 'https://' + HOST_SERVER + '/api'
  MQTT_URL = 'wss://' + HOST_SERVER + '/mqtt'
}

const Package = require('../../package.json')
const APPS_AUTH_USER = '__apps__' + Package.name

// const STORAGE = 'local'
const STORAGE = 'session'

const USE_AUTH = true
// const USE_AUTH = false

exports.APPS_AUTH_USER = APPS_AUTH_USER
exports.STORAGE_TYPE = STORAGE
exports.USE_AUTH = USE_AUTH

exports.MQTT_URL = MQTT_URL
exports.API_ROUTE = API_ROUTE
