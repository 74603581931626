import { createSlice } from '@reduxjs/toolkit'
import NFetch from 'src/libcore/nfetch'
import { API_ROUTE } from 'src/libcore/config'
import store from 'src/store'

export const getWorkingLocation = async (reload = false) => {
  if (reload) {
    return NFetch.Get(API_ROUTE + '/locations')
  }
  const { location } = store.getState()
  if (location.area.length === 0) {
    return NFetch.Get(API_ROUTE + '/locations')
  } else {
    return Promise.resolve(location.area)
  }
}

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    area: [],
  },
  reducers: {
    locationArea(state, action) {
      state.area = action.payload
    },
  },
})

export const { locationArea } = locationSlice.actions
export default locationSlice.reducer
