import { getAuthUserAccessToken } from './auth'
import Authentication from './func/authentication'

let stringAuthorization = {}

class NFetch {
  static redirectUnAuth = true
  static useBearer = true

  static UsedBasicAuth(username, password) {
    stringAuthorization.Authorization =
      'Basic ' + Buffer.from(`${username}:${password}`, 'binary').toString('base64')
  }
  static RemoveBasicAuth() {
    stringAuthorization = {}
  }
  static UsedGeneralAuth(stringAuth) {
    stringAuthorization.Authorization = stringAuth
  }
  static RemoveGeneralAuth() {
    stringAuthorization = {}
  }

  static GetAuthorization() {
    if (this.useBearer) {
      return 'Bearer ' + getAuthUserAccessToken()
    }
    return getAuthUserAccessToken()
  }

  static Post(url, data, redirectUnAuth = true) {
    this.redirectUnAuth = redirectUnAuth
    return this.PostQuery(url, data)
  }
  static PostQuery(url, data, query, encodeUri = true, redirectUnAuth = true) {
    this.redirectUnAuth = redirectUnAuth
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }
    // console.log(requestOptions)
    if (typeof data !== 'undefined') {
      requestOptions.body = JSON.stringify(data)
    }
    // http://localhost:3006/api/Devices/update?where=%7B%22id%22%3A%224%22%7D
    // http://localhost:3006/api/AppUsers/login?include=user

    // query = {include:[]},{where:{}}
    // login?include=user
    // update?where=%7B%22id%22%3A%225%22%7D
    let querys = ''
    if (typeof query !== 'undefined') {
      let keys = Object.keys(query)
      if (encodeUri) {
        if (keys.length > 0) {
          querys = `?${keys[0]}=${encodeURIComponent(JSON.stringify(query[keys[0]]))}`
        }
      } else {
        let _str = '?'
        for (const key in query) {
          _str += key + '=' + query[key] + '&'
        }
        querys = _str.substr(0, _str.length - 1)
      }
    }

    return this.fetchData(`${url}${querys}`, requestOptions)
  }

  static Get(url, redirectUnAuth = true) {
    this.redirectUnAuth = redirectUnAuth
    return this.GetQuery(url)
  }
  static GetQuery(url, query, redirectUnAuth = true) {
    this.redirectUnAuth = redirectUnAuth
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }

    let querys = ''
    if (typeof query !== 'undefined') {
      let _str = '?'
      for (const key in query) {
        _str += key + '=' + query[key] + '&'
      }
      querys = _str.substr(0, _str.length - 1)
    }

    return this.fetchData(`${url}${querys}`, requestOptions)
  }
  static GetWithFilter(url, query, redirectUnAuth = true) {
    this.redirectUnAuth = redirectUnAuth
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }

    if (typeof query === 'undefined') {
      query = ''
    } else {
      //?filter=%7B%22and%22%3A%5B%7B%22timestamp%22%3A%7B%22gt%22%3A%222019-02-12T12%3A00%3A00Z%22%7D%7D%2C%7B%22status%22%3A%22pick%22%7D%5D%7D
      query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }

    return this.fetchData(`${url}${query}`, requestOptions)
  }

  static Patch(url, data) {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
      body: JSON.stringify(data),
    }

    return this.fetchData(`${url}`, requestOptions)
  }

  static Delete(url) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }

    return this.fetchData(`${url}`, requestOptions)
  }
  static DeleteQuery(url, query) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }

    let querys = ''
    if (typeof query !== 'undefined') {
      let _str = '?'
      for (const key in query) {
        _str += key + '=' + query[key] + '&'
      }
      querys = _str.substr(0, _str.length - 1)
    }

    return this.fetchData(`${url}${querys}`, requestOptions)
  }
  static DeleteWithFilter(url, query) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }

    if (typeof query === 'undefined') {
      query = ''
    } else {
      query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }

    return this.fetchData(`${url}${query}`, requestOptions)
  }

  static PutQuery(url, data, query) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
      body: JSON.stringify(data),
    }

    let querys = ''
    if (typeof query !== 'undefined') {
      let _str = '?'
      for (const key in query) {
        _str += key + '=' + query[key] + '&'
      }
      querys = _str.substr(0, _str.length - 1)
    }

    return this.fetchData(`${url}${querys}`, requestOptions)
  }
  static PutWithFilter(url, data, query) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
      body: JSON.stringify(data),
    }

    if (typeof query === 'undefined') {
      query = ''
    } else {
      query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }

    return this.fetchData(`${url}${query}`, requestOptions)
  }

  static UploadFile(url, data) {
    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: this.GetAuthorization(),
        ...stringAuthorization,
      },
    }
    if (typeof data !== 'undefined') {
      requestOptions.body = data
    }

    return this.fetchData(url, requestOptions)
  }
  static UploadFileWithProgress(url, data, cb) {
    const ProgressHandler = (e) => {
      var percent = (e.loaded / e.total) * 100
      cb(null, { complete: false, progress: Math.round(percent) })
    }
    const SuccessHandler = (e) => {
      if (e.currentTarget.status < 400) {
        cb(null, { complete: true, message: e.target.responseText })
      } else {
        cb(JSON.parse(e.currentTarget.responseText))
      }
    }
    const ErrorHandler = (e) => {
      console.log(e)
      cb('Upload failed!' + e)
    }
    const AbortHandler = () => {
      cb('Upload aborted!')
    }

    var xhr = new XMLHttpRequest()
    xhr.upload.addEventListener('progress', ProgressHandler, false)
    xhr.addEventListener('load', SuccessHandler, false)
    xhr.addEventListener('error', ErrorHandler, false)
    xhr.addEventListener('abort', AbortHandler, false)
    xhr.open('POST', url)
    xhr.setRequestHeader('Authorization', getAuthUserAccessToken())
    xhr.send(data)
  }

  static async fetchData(url, options) {
    try {
      // console.log(options)
      let response = await fetch(`${url}`, options)

      // Reset Basic Auth Object
      stringAuthorization = {}

      // console.log(response)
      if (response.status === 204) {
        // No content deliver
        return Promise.resolve()
      }

      let text = await response.text()
      // console.log(text)
      let jsonData = null
      try {
        jsonData = text && JSON.parse(text)
      } catch (error) {
        return Promise.resolve(text)
      }

      if (response.status === 200) {
        return Promise.resolve(jsonData)
      } else if (response.status === 401) {
        // console.log(data)
        let __code = jsonData.error.code || null
        if (__code === 'LOGIN_FAILED') {
          return Promise.reject(jsonData.error.message)
        } else if (jsonData.error.message.includes('jwt expired')) {
          if (this.redirectUnAuth) {
            Authentication.logout()
            Authentication.removeAuthUser()
            window.location.assign('/login')
            return Promise.reject()
          }
        } else {
          // Unauthorized API access
          // Remove current user and move to login page
          // console.log(data.error.message)
          if (this.redirectUnAuth) {
            Authentication.logout()
            Authentication.removeAuthUser()
            window.location.assign('/login')
            return Promise.reject()
          }

          return Promise.reject(jsonData.error.message)
        }
      } else {
        return Promise.reject(jsonData)
      }
    } catch (error) {
      // console.log('====', error)
      // return Promise.reject(error)
      if (error instanceof TypeError) {
        return Promise.reject(error.message)
      } else {
        return Promise.reject(error)
      }
    }
  }
}
export default NFetch
