import { createSlice } from '@reduxjs/toolkit'
import NFetch from 'src/libcore/nfetch'
import { API_ROUTE } from 'src/libcore/config'
import store from 'src/store'

export const getVehicleType = async (reload = false) => {
  if (reload) {
    return NFetch.Get(API_ROUTE + '/compound-vehicle-types')
  }
  const { vehicleType } = store.getState()
  if (vehicleType.data.length === 0) {
    return NFetch.Get(API_ROUTE + '/compound-vehicle-types')
  } else {
    return Promise.resolve(vehicleType.data)
  }
}
export const vehicleTypeSlice = createSlice({
  name: 'vehicleType',
  initialState: {
    data: [],
  },
  reducers: {
    vehicleTypeData(state, action) {
      state.data = action.payload
    },
  },
})

export const { vehicleTypeData } = vehicleTypeSlice.actions
export default vehicleTypeSlice.reducer
