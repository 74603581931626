import { createSlice } from '@reduxjs/toolkit'
import NFetch from 'src/libcore/nfetch'
import { API_ROUTE } from 'src/libcore/config'
import store from 'src/store'

export const addNewEmployerCompany = async (param) => {
  return NFetch.Post(API_ROUTE + '/employer-companies', param)
}

export const getTenant = async (reload = false) => {
  if (reload) {
    return NFetch.GetWithFilter(API_ROUTE + '/tenants', {
      fields: ['id', 'name', 'address', 'phoneNo'],
      include: [
        {
          relation: 'organizationDepartments',
          scope: { fields: ['name', 'id', 'tenantId'] },
        },
      ],
    })
  }
  const { organization } = store.getState()
  if (organization.tenant.length === 0) {
    return NFetch.GetWithFilter(API_ROUTE + '/tenants', {
      fields: ['id', 'name', 'address', 'phoneNo'],
      include: [
        {
          relation: 'organizationDepartments',
          scope: { fields: ['name', 'id', 'tenantId'] },
        },
      ],
    })
  } else {
    return Promise.resolve(organization.tenant)
  }
}

export const getEmployerCompany = async (reload = false) => {
  if (reload) {
    return NFetch.GetWithFilter(API_ROUTE + '/employer-companies', { fields: ['id', 'name'] })
  }
  const { organization } = store.getState()
  if (organization.employerCompany.length === 0) {
    return NFetch.GetWithFilter(API_ROUTE + '/employer-companies', { fields: ['id', 'name'] })
  } else {
    return Promise.resolve(organization.employerCompany)
  }
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    employerCompany: [],
    tenant: [],
    department: [],
  },
  reducers: {
    organizationEmployerCompany(state, action) {
      state.employerCompany = action.payload
    },
    organizationTenant(state, action) {
      state.tenant = action.payload
    },
    organizationDepartment(state, action) {
      state.department = action.payload
    },
  },
})

export const { organizationEmployerCompany, organizationTenant, organizationDepartment } =
  organizationSlice.actions
export default organizationSlice.reducer
